exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../../../src/templates/ArticlePage.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */),
  "component---src-templates-internal-page-tsx": () => import("./../../../src/templates/InternalPage.tsx" /* webpackChunkName: "component---src-templates-internal-page-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/LandingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-sot-d-page-tsx": () => import("./../../../src/templates/SotDPage.tsx" /* webpackChunkName: "component---src-templates-sot-d-page-tsx" */),
  "component---src-templates-tree-page-tsx": () => import("./../../../src/templates/TreePage.tsx" /* webpackChunkName: "component---src-templates-tree-page-tsx" */),
  "slice---src-slices-alert-banner-index-tsx": () => import("./../../../src/slices/AlertBanner/index.tsx" /* webpackChunkName: "slice---src-slices-alert-banner-index-tsx" */),
  "slice---src-slices-header-content-index-tsx": () => import("./../../../src/slices/HeaderContent/index.tsx" /* webpackChunkName: "slice---src-slices-header-content-index-tsx" */),
  "slice---src-slices-related-items-sidebar-index-tsx": () => import("./../../../src/slices/RelatedItemsSidebar/index.tsx" /* webpackChunkName: "slice---src-slices-related-items-sidebar-index-tsx" */)
}

