exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (process.env.NODE_ENV === `production`) {
    setTimeout(() => {
      window.dataLayer.push({
        event: `gatsby-route-change`,
        pageTitle: document.title,
        pageUrl: location.href,
        pagePath: location.pathname,
      });
    }, 100);
  }
};
